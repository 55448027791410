import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsCallout from '@/components/DocsCallout'
import DocsExample from '@/components/DocsExample'
import { CDataTable } from '@coreui/vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { faTrashRestore } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import snotify from 'vue3-snotify';
import 'vue3-snotify/style';
import axios from 'axios'
import VueAxios from 'vue-axios'
import BootstrapVue3 from 'bootstrap-vue-3'
 import { CSmartTable } from '@coreui/vue-pro'


library.add(faUserSecret, faEdit, faTimesCircle, faTrashRestore)
const app = createApp(App)

app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(VueAxios, axios)
app.use(snotify);
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('DocsCallout', DocsCallout)
app.component('DocsExample', DocsExample)
app.component('CDataTable', CDataTable)
app.component('CSmartTable',CSmartTable)
app.component('font-awesome-icon', FontAwesomeIcon)
app.use(BootstrapVue3)
app.mount('#app')
