export default [
  {
    component: 'CNavItem',
    name: 'Tableau de bord',
    to: '/dashboard',
    icon: 'cil-speedometer',

  },


  {
    component: 'CNavTitle',
    name: 'Utilisateurs',

  },
  {
    component: 'CNavItem',
    name: 'Utilisateurs actifs',
    to: '/user',
    icon: 'cil-group',

  },
  {
    component: 'CNavItem',
    name: 'Utilisateurs inactifs',
    to: '/deactiveuser',
    icon: 'cil-group',

  },







  // {
  //   component: 'CNavItem',
  //   name: 'Download CoreUI',
  //   href: 'http://coreui.io/vue/',
  //   icon: { name: 'cil-cloud-download', class: 'text-white' },
  //   _class: 'bg-success text-white',
  //   target: '_blank'
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Try CoreUI PRO',
  //   href: 'http://coreui.io/pro/vue/',
  //   icon: { name: 'cil-layers', class: 'text-white' },
  //   _class: 'bg-danger text-white',
  //   target: '_blank'
  // }
]
