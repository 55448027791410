export const userService = {
  login,
  logout,
  /*register,
    getAll,*/
}

//const apiUrl = 'https://174.138.181.206:4000'
 const apiUrl = 'https://beninrmobilitypro.raynis.co' //Prod

function login(email, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, password }),
  }

  return fetch(`${apiUrl}/auth/login`, requestOptions)
    .then(handleResponse)
    .then((user) => {

      // login successful if there's a jwt token in the response
      if (user.tokenSalt) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        //localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('user', JSON.stringify(user))
      }
      return user
    })

}


/* function getProfile() {
  //let user = JSON.parse(localStorage.getItem('user'));
  let user = JSON.parse(sessionStorage.getItem('user'))
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(`${apiUrl}/image/${user.userAvatarCode}`, requestOptions).then(
    handleResponse,
  )
} */

function logout() {
  localStorage.removeItem('user')
}

/*function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };
    return fetch(`/users/register`, requestOptions).then(handleResponse);
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`/users`, requestOptions).then(handleResponse);
}*/

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text)
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout()
        location.reload(true)
      }
      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }
    return data
  })
}
